<template login>
  
  
  <main class="min-h-screen h-screen" style="display: grid; place-items: center; background-size: cover; background-position: center center" :style="{ backgroundImage: 'url(' + backgroundUrl + ')' }">
    <section class="pa-8 rounded-xl mx-auto  border d-flex flex-column align-center ga-3" style="max-width: 36rem; background-color: rgba(255,255,255,0.8); z-index: 1;">
      <h1 v-if="backgroundUrl" class="mb-2 text-center bg-transparent" style="font-weight: 400; font-size: 30px">Welcome to Westreportal!</h1>
      <h1 v-else class="mb-2 text-center bg-transparent" style="font-weight: 400; font-size: 30px">Welcome!</h1>
            
      <form method="post" action="/login" @submit.prevent="submit">
        <v-btn v-if="$page.props.tenancy.auth_method === 'Microsoft'"
            type="submit"
            :disabled="form.processing"
            :class="{'bg-grey' : form.processing , 'bg-blue-accent-3': isHovering}"
            class="mt-2"
        >
          <img src="/ms-logo-login.png" alt="" class="mr-3">
          Sign in with Microsoft
        </v-btn>
        <v-btn
            v-if="$page.props.tenancy.auth_method === 'Google'"
            type="submit"
            :disabled="form.processing"
            :class="{'bg-grey' : form.processing , 'bg-blue-accent-3': isHovering}"
            class="gsi-material-button">
          <div class="gsi-material-button-content-wrapper">
            <div class="gsi-material-button-icon">
              <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" xmlns:xlink="http://www.w3.org/1999/xlink" style="display: block;">
                <path fill="#EA4335" d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"></path>
                <path fill="#4285F4" d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"></path>
                <path fill="#FBBC05" d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"></path>
                <path fill="#34A853" d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"></path>
                <path fill="none" d="M0 0h48v48H0z"></path>
              </svg>
            </div>
            <span class="gsi-material-button-contents">Sign in with Google</span>
          </div>
        </v-btn>
        <template v-if="$page.props.flash.message">
          <br>
          <br>
          <div class="text-error text-center">{{$page.props.flash.message}}</div>
        </template>
      </form>
  
      
      <form v-if="$page.props.tenancy.auth_method === 'Local'" @submit.prevent="submit">
        <div class="mb-6">
          <label
              class="mb-1 text-uppercase font-weight-bold text-grey-darken-2"
              style="display: block; font-size: 15px"
              for="email"
          >
            Email
          </label>
          
          <input
              v-model="form.email"
              class="border rounded pa-2 w-100"
              type="email"
              name="email"
              id="email"
          />
          
          <p
              v-if="form.errors.email"
              v-text="form.errors.email"
              class="text-red-accent-3 text-xs mt-2"
          ></p>
        </div>
        
        <div class="mb-6">
          <label
              class="mb-1 text-uppercase font-weight-bold text-grey-darken-2"
              style="display: block; font-size: 15px"
              for="password"
          >
            Password
          </label>
          
          <input
              v-model="form.password"
              class="border rounded pa-2 w-100"
              type="password"
              name="password"
              id="password"
          />
          
          <p
              v-if="form.errors.password"
              v-text="form.errors.password"
              class="text-red-accent-3 text-xs mt-2"
          ></p>
        </div>
        
        <div class="mb-6">
          <v-hover>
            <template v-slot:default="{ isHovering, props }">
              <button
                  v-bind="props"
                  type="submit"
                  class="bg-blue-accent-2 text-white rounded-xl font-weight-medium py-2 px-10"
                  :disabled="form.processing"
                  :class="{'bg-grey' : form.processing , 'bg-blue-accent-3': isHovering}"
              >
                Submit
              </button>
            </template>
          </v-hover>
        </div>
      </form>
    </section>
  </main>
</template>

<script setup lang="ts">

import {useForm} from "@inertiajs/vue3";

defineOptions({layout: null})

const props = defineProps({
  backgroundUrl: String,
})

console.log(props.backgroundUrl)
console.log('hello');

let form = useForm({
  email: '',
  password: '',
});

let submit = () => {
  form.post('/login');
};

</script>

<style scoped>
.gsi-material-button {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -webkit-appearance: none;
  background-color: WHITE;
  background-image: none;
  border: 1px solid #747775;
  -webkit-border-radius: 20px;
  border-radius: 20px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #1f1f1f;
  cursor: pointer;
  font-family: 'Roboto', arial, sans-serif;
  font-size: 14px;
  height: 40px;
  letter-spacing: 0.25px;
  outline: none;
  overflow: hidden;
  padding: 0 12px;
  position: relative;
  text-align: center;
  -webkit-transition: background-color .218s, border-color .218s, box-shadow .218s;
  transition: background-color .218s, border-color .218s, box-shadow .218s;
  vertical-align: middle;
  white-space: nowrap;
  width: auto;
  max-width: 400px;
  min-width: min-content;
}

.gsi-material-button .gsi-material-button-icon {
  height: 20px;
  margin-right: 12px;
  min-width: 20px;
  width: 20px;
}

.gsi-material-button .gsi-material-button-content-wrapper {
  -webkit-align-items: center;
  align-items: center;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap;
  height: 100%;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.gsi-material-button .gsi-material-button-contents {
  -webkit-flex-grow: 1;
  flex-grow: 1;
  font-family: 'Roboto', arial, sans-serif;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: top;
}

.gsi-material-button .gsi-material-button-state {
  -webkit-transition: opacity .218s;
  transition: opacity .218s;
  bottom: 0;
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.gsi-material-button:disabled {
  cursor: default;
  background-color: #ffffff61;
  border-color: #1f1f1f1f;
}

.gsi-material-button:disabled .gsi-material-button-contents {
  opacity: 38%;
}

.gsi-material-button:disabled .gsi-material-button-icon {
  opacity: 38%;
}

.gsi-material-button:not(:disabled):active .gsi-material-button-state,
.gsi-material-button:not(:disabled):focus .gsi-material-button-state {
  background-color: #303030;
  opacity: 12%;
}

.gsi-material-button:not(:disabled):hover {
  -webkit-box-shadow: 0 1px 2px 0 rgba(60, 64, 67, .30), 0 1px 3px 1px rgba(60, 64, 67, .15);
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, .30), 0 1px 3px 1px rgba(60, 64, 67, .15);
}

.gsi-material-button:not(:disabled):hover .gsi-material-button-state {
  background-color: #303030;
  opacity: 8%;
}

</style>
